import React from "react";

import "./importantLinks.css";

import { NIEPID_Logo } from "../imagepath";
import { niepmd_logo } from "../imagepath";
import { ministry } from "../imagepath";
import { NIMHNS } from "../imagepath";
import { tnt } from "../imagepath";
import { rci } from "../imagepath";


const ImportantLinks = () => {
    return (
        <>
            <div className="main-wrapper">
                <div className="page-banner mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">Important links</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="ux-design-five">
                        <div className="row">
                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five d-block">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={NIEPID_Logo}
                                                />

                                            </div>
                                            {/* Product image*/}

                                            {/* Product Content*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    National Institute for the Empowerment of Person with Intellectual Disabilities
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                NIEPID, India&apos;s leading institute for intellectual disabilities (ID), empowers individuals through programs, research, and advocacy. It offers crucial services like early intervention, education, training, and rehabilitation...
                                            </p>
                                        </div>
                                        {/* Product Content*/}
                                    </div>

                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">
                                        <a href="https://niepid.nic.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={niepmd_logo}
                                                />

                                            </div>
                                            {/* Product image*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    National Institute for Empowerment of Persons with Multiple Disabilities
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                NIEPMD, India&apos;s champion for individuals with multiple disabilities (deafness, blindness, mental illness, etc.). They offer life-changing programs like B.P.O. training and teacher specialization, conduct research...
                                            </p>
                                        </div>

                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">
                                        <a href="https://niepmd.tn.nic.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={ministry}
                                                />

                                            </div>
                                            {/* Product image*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    Department of Empowerment of Persons with Disabilities                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                The Department of Empowerment of Persons with Disabilities (DEPwD) in India works tirelessly to improve the lives of millions. It tackles a wide range of issues, from issuing disability certificates to advocating for...
                                            </p>
                                        </div>

                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">

                                        <a href="https://depwd.gov.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={NIMHNS}
                                                />

                                            </div>
                                            {/* Product image*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    National Institute of Mental Health and Neuro-Sciences
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                NIMHANS, India&apos;s apex institute for mental health and neurosciences, shines a light on hope. Based in Bangalore, they offer comprehensive care, train professionals, and drive cutting-edge research. From early...
                                            </p>
                                        </div>
                                        {/* Product Content*/}

                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">
                                        <a href="https://nimhans.ac.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={ministry}
                                                />

                                            </div>
                                            {/* Product image*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    Department of Social Justice and Empowerment
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                The Department of Social Justice and Empowerment (DSJE) serve as a cornerstone for India&apos;s marginalized communities. They tackle numerous challenges head-on, championing the rights of Scheduled Castes...
                                            </p>
                                        </div>
                                        {/* Product Content*/}

                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">
                                        <a href="https://socialjustice.gov.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            {/* Col */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={ministry}
                                                />

                                            </div>
                                            {/* Product image*/}
                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    Ministry of Electronics and Information Technology                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                Steering India&apos;s digital revolution, the Ministry of Electronics and Information Technology (MeitY) plays a pivotal role. It focuses on two key areas: boosting the electronics industry and shaping the IT landscape. From promoting...
                                            </p>
                                        </div>
                                        {/* Product Content*/}

                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">

                                        <a href="https://www.meity.gov.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={tnt}
                                                />

                                            </div>
                                            {/* Product image*/}

                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    The National Trust
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                India&apos;s National Trust, championing &quot;Empowering Abilities,&quot; fights for individuals with autism, cerebral palsy, and other disabilities. They&apos;ve provided a lifeline through guardianship, education, training, and...
                                            </p>
                                        </div>
                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">

                                        <a href="https://thenationaltrust.gov.in/content/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                            {/* Col */}

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={rci}
                                                />

                                            </div>
                                            {/* Product image*/}

                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    Rehabilitation Council of India
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                            India&apos;s RCI, acts as the regulatory body for disability rehabilitation and special education. They ensure standardized training, maintain a central register of qualified professionals, and set the bar for ethical practices...
                                                                                   </p>
                                        </div>
                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">

                                        <a href="https://rehabcouncil.nic.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five">
                                    <div className="product-five">
                                        {/* Product image*/}
                                        <div className="d-flex heading mb-3">
                                            <div className="product-img-card w-50">
                                                <img
                                                    className=""
                                                    alt=""
                                                    src={ministry}
                                                />

                                            </div>
                                            {/* Product image*/}

                                            <div className="ms-3 w-50 d-flex align-items-center">
                                                <strong className="product-five-title text-blue">
                                                    Ministry of Health and Family Welfare
                                                </strong>

                                            </div>
                                        </div>
                                        <div className="product-content-five p-0">
                                            <p>
                                                Since 1947, the Ministry of Health and Family Welfare (MoHFW) has steered India&apos;s healthcare trajectory. From tackling major diseases to promoting family planning, their work touches countless lives. They...
                                            </p>
                                        </div>

                                        {/* Product Content*/}
                                    </div>
                                    {/* Ovelay button */}
                                    <div className="joing-course-ovelay">

                                        <a href="https://www.mohfw.gov.in/" className="joing-course-btn">
                                            Know more
                                        </a>
                                    </div>
                                    {/* Ovelay button */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ImportantLinks;
