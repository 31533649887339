import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Typography from "@mui/material/Typography";
import { Grid, Hidden } from "@mui/material";
import Box from "@mui/material/Box";
import "./index.css";
import edeekshaamLogo from "./headerImage/e-deekshamFull.png";
import manuuFullLogo from "./headerImage/manuu-full.png";
import manuuPlainLogo from "./headerImage/manuu-plain.png";
import meityFullLogo from "./headerImage/meity.png";
import meityPlain from "./headerImage/ashoka-chakra.png";

export const Header = () => {
  const location = useLocation();
  // change header background on scroll
  const [navbar, setNavbar] = useState(false);

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // Mobile Menu toggle
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
  };

  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };

  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    scrollToTop();
    hideMobileMenu();
  };

  window.addEventListener("scroll", changeHeaderBackground);
  return (
    <div className="row m-0 p-0">
      <header className="header-three p-0">
        <div className="row m-0 p-0 mb-2">
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
              "@media (max-width: 500px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2} sm={2} md={2} lg={2} className="ministry-logo">
                <Hidden smDown>
                  <Link
                    to="https://www.meity.gov.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="master-three-images">
                      <img
                        crossOrigin="anonymous"
                        src={meityFullLogo}
                        className="img-fluid"
                        alt="Banner 1"
                      />
                    </div>
                  </Link>
                </Hidden>

                <Hidden smUp>
                  <Link
                    to="https://www.meity.gov.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      crossOrigin="anonymous"
                      src={meityPlain}
                      alt="ashoka-chakra"
                      className="img-fluid"
                    />
                  </Link>
                </Hidden>
              </Grid>

              {/* <Hidden smUp>
              <Grid item sm={1} >
              &nbsp; &nbsp; 
              </Grid>
              </Hidden> */}

              <Grid
                item
                sm={7}
                xs={7}
                className="e-deekshaam-logo"
                style={{ paddingLeft: "0px" }}
              >
                <Link to="/" rel="noopener noreferrer">
                  <Link to="/about-edeeksham" onClick={scrollToTop}>
                    <div className="d-flex justify-content-center">
                      <img
                        crossOrigin="anonymous"
                        src={edeekshaamLogo}
                        alt="Banner 2"
                        className="img-fluid"
                        style={{
                          width: "160px",
                          height: "auto",
                          // "@media (min-width: 600px)": {
                          //   width: "220px",
                          //   height:"auto"
                          // },
                        }}
                      />

                      <strong className="text-yellow">Beta</strong>
                    </div>
                  </Link>
                </Link>
              </Grid>

              {/* <Hidden smDown>
                <Grid item sm={6} className="top-header-text"> */}
              {/* <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      "@media (max-width: 768px)": {
                        fontSize: "14px",
                      },
                      fontFamily: "arial",
                      fontWeight: "bold",
                    }}
                  >
                    Adaptive Assistive System for Children with Moderate
                    Intellectual Disability
                  </Typography> */}
              {/* </Grid>
              </Hidden> */}

              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                justifyContent="flex-end"
                className="manuu-logo"
              >
                {/* Hide the first image on mobile devices */}
                <Hidden smDown>
                  <Link
                    to="https://manuu.edu.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <img
                      crossOrigin="anonymous"
                      src={manuuFullLogo}
                      // className="manuu-logo-1"
                      alt="Banner 4"
                      // style={{ width: "70%", height: "auto" }}
                      className="img-fluid"
                    />
                  </Link>
                </Hidden>

                {/* Show the second image on mobile devices */}
                <Hidden smUp>
                  <Link
                    to="https://manuu.edu.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <img
                      crossOrigin="anonymous"
                      src={manuuPlainLogo}
                      alt="manuu-plain"
                      className="img-fluid"
                      // className="manuu-logo-2"
                      // style={{
                      //   width: "28%",
                      //   height: "auto",
                      //   marginTop: "-8px",
                      // }}
                    />
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="header-fixed-three header-fixed z-2 position-relative">
          <nav
            className={`navbar navbar-expand-lg header-nav-three ${
              navbar ? "fixed z-3 top-0 add-header-bg" : "bg-transparent"
            }`}
          >
            <div className="container">
              <div className="navbar-header d-lg-none">
                <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img
                      src={edeekshaamLogo}
                      className="img-fluid"
                      alt="Logo"
                    />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#;"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>

                <ul className="main-nav">
                  <li
                    className={`has-submenu ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                  >
                    <Link to="/" onClick={handleClick}>
                      Home{" "}
                    </Link>
                  </li>

                  <li
                    className={`has-submenu ${
                      location.pathname.includes("/about") ? "active" : ""
                    }`}
                  >
                    <Link to="#">
                      About{" "}
                      <i
                        className="fas fa-chevron-down"
                        onClick={openMobileSubMenu2}
                      ></i>
                    </Link>
                    <ul
                      className={
                        mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                      }
                    >
                      <li
                        className={
                          location.pathname === "/about-edeeksham"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/about-edeeksham" onClick={handleClick}>
                          ε-Deekshaam
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/about-manuu" ? "active" : ""
                        }
                      >
                        <Link to="/about-manuu" onClick={handleClick}>
                          MANUU
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`has-submenu ${
                      location.pathname.includes("/events") ||
                      location.pathname === "/workshop" ||
                      location.pathname === "/other-events"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="#">
                      Events
                      <i
                        className="fas fa-chevron-down"
                        onClick={openMobileSubMenu3}
                      ></i>
                    </Link>
                    <ul
                      className={
                        mobileSubMenu3
                          ? "submenu first-submenu submenuShow"
                          : "submenu first-submenu"
                      }
                    >
                      <li
                        className={
                          location.pathname === "/workshop" ? "active" : ""
                        }
                      >
                        <Link to="/workshop">Workshops</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/other-events" ? "active" : ""
                        }
                      >
                        <Link to="/other-events">Other Events</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === "/collaboration" ? "active" : ""
                    }
                  >
                    <Link to="/collaboration" onClick={handleClick}>
                      Collaboration
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/important-links" ? "active" : ""
                    }
                  >
                    <Link to="/important-links" onClick={handleClick}>
                      Important Links
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/contact-us" ? "active" : ""
                    }
                  >
                    <Link to="/contact-us" onClick={handleClick}>
                      Contact Us
                    </Link>
                  </li>
                  <li className="login-link">
                    <Link to="https://dashboard.edeekshaam.in/login">
                      Login{" "}
                    </Link>
                  </li>
                  <li className="login-link">
                    <Link to="https://dashboard.edeekshaam.in/register">
                      Register
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="nav header-navbar-rht align-items-center">
                <li className="nav-item">
                  <Link
                    className="nav-link login-three-head button"
                    to="https://dashboard.edeekshaam.in/login"
                  >
                    <span>Login</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link signin-three-head"
                    to="https://dashboard.edeekshaam.in/register"
                  >
                    Register
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
