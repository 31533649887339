import React, { useState } from "react";
import location from "../../assets/img/icon/location.svg";
import email from "../../assets/img/icon/email.svg";
import telephone from "../../assets/img/icon/telephone.svg";
import "../../assets/css/style.css";

import env1 from "../../assets/img/contact-us/env1.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field is firstName or lastName, allow only alphabetic characters
    if (name === "firstName" || name === "lastName") {
      const formattedValue = value.replace(/[^a-zA-Z]/g, "");
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    } else if (name === "mobileNumber") {
      // If the field is mobileNumber, limit input to 10 digits
      const formattedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation here before submitting
    const newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = "Please enter your first name";
    }
    if (!formData.lastName) {
      newErrors.lastName = "Please enter your last name";
    }
    if (!formData.email) {
      newErrors.email = "Please enter your email";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!formData.message) {
      newErrors.message = "Please write your concern";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    axios
      .post(`https://api.edeekshaam.in/v1/users/public/getInTouch`, formData)
      .then((response) => {
        if (response.status === 200) {
          console.log("response", response);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            message: "",
          });
          Swal.fire({
            title: "Success!",
            text: "Your message has been submitted. Thank you!",
            icon: "success",
            customClass: {
              confirmButton: "email-success-popup",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isValidEmail = (email) => {
    // You can implement more sophisticated email validation here if needed
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <>
      <div className="main-wrapper ">
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Contact us</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-7 mb-3 mb-lg-0">
              <div
                className="support-wrap p-1"
                style={{ width: "100%", height: "100%" }}
              >
                <iframe
                  style={{
                    width: "inherit",
                    height: "inherit",
                    padding: "10px",
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15034.167098348324!2d78.36107973729702!3d17.430546496156715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1680255417198!5m2!1sen!2sin"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="support-wrap">
                <img className="" style={{ width: "20px" }} src={location} />
                <h4 className="mt-3">Address</h4>
                <p className="lh-lg">
                  {" "}
                  Department of Computer Science & Information Technology,{" "}
                  <br /> Maulana Azad National Urdu University,
                  <br /> Gachibowli, Hyderabad,Telangana, India - 500032
                </p>
              </div>
              <div className="support-wrap mt-3">
                <img className="" style={{ width: "20px" }} src={email} />
                <h4 className="mt-2">Mail us</h4>
                <Link to="mailto:contact@edeekshaam.in">
                  <p>contact@edeekshaam.in</p>
                </Link>
                <img className="" style={{ width: "20px" }} src={telephone} />
                <h4 className="mt-2">Call us on</h4>
                <p className="m-0">
                  <a href="tel:04023006612">040 23006612 (Extn : 4002)</a>
                </p>
                <p className="m-0">
                  Call Timing: 9:30 AM to 5:30 PM (Monday to Friday)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex justify-content-center">
                <img className="img-fluid" src={env1} alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-md-8 mx-auto mt-5 pt-5 mb-3 mb-lg-0">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <p className="text-danger">{errors.firstName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <p className="text-danger">{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                  {errors.mobileNumber && (
                    <p className="text-danger">{errors.mobileNumber}</p>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    rows={4}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {errors.message && (
                    <p className="text-danger">{errors.message}</p>
                  )}
                </div>

                {isLoading ? (
                  <button className="btn-submit p-1 mb-3" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Sending...
                  </button>
                ) : (
                  <button type="submit" className="btn-submit px-3 py-1">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
