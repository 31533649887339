import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import department from "../../../assets/img/about/department.png";
import manuu from "../../../assets/img/about/manuu.jpg";

export const TopCategories2 = () => {
  var course = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1170: {
        items: 2,
      },
    },
  };
  return (
    <OwlCarousel
      {...course}
      className="owl-carousel home-three-favourite-carousel owl-theme aos topCat"
    >
      <div className="">
        <div className="favourite-item flex-fill">
          <div className="categories-icon">
            <img
              className="img-fluid border rounded"
              src={manuu}
              alt="Angular Development"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="favourite-item flex-fill">
          <div className="categories-icon">
            <img
              className="img-fluid border rounded"
              src={department}
              alt="Angular Development"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="favourite-item flex-fill">
          <div className="categories-icon">
            <img
              className="img-fluid border rounded"
              src={manuu}
              alt="Angular Development"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="favourite-item flex-fill">
          <div className="categories-icon">
            <img
              className="img-fluid border rounded"
              src={department}
              alt="Angular Development"
            />
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};
