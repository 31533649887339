import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom';
// import { Blog1, Blog2, Blog3, BlogBanner, Icon22, Icon23, User } from '../imagepath';
// import BlogHeader from './header';
import video from "../workshop/videos/videoplayback1.mp4";
// import Blog8 from '../../assets/img/banner-img-1.png'
import Icon22 from '../header/headerImage/icon-22.svg'
import Icon23 from '../header/headerImage/icon-23.svg'



const workshopBlogDetails2 = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  })

  return (
    <>
        <div className='main-wrapper'>

            <section className="course-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-12">
                    {/* Blog Post */}
                    <div className="blog">
                    


                    
                      <div className="blog-info clearfix">
                        
                      </div>
                      <h3 className="blog-title">
                      {/* {workshopData.title} */}
                      Topic validation workshop
                    
                      </h3>
                      <div className="post-left mb-3">
                          <ul>
                            {/* <li>
                              <div className="post-author">
                                <Link  to="/instructor-profile">
                                  <img src={User} alt="Post Author" /> 
                                  <span>Ruby Perrin</span>
                                </Link> 
                              </div>
                            </li> */}
                            <li>
                              <img
                                className="img-fluid"
                                src={Icon22}
                                alt=""
                              />
                             20 August 2022
                            </li>
                            <li>
                              <img
                                className="img-fluid"
                                src={Icon23}
                                alt=""
                              />
                              Events, Workshops
                            </li>
                          </ul>
                        </div>
                      <div className="blog-content">
                        <p>
                        In the workshop,the experts carefully validated the topics intended for intellectually disabled childrenensuring their relevance, suitability,and alignment with the children needs and learning objectives.
                        </p>
                        <p>
                        The process of developing educational content for intellectually disabled children requires a high level of care, expertise, and sensitivity. In the workshop setting described, experts engage in a thorough validation process to ensure that the topics chosen are not only relevant but also suitable and aligned with the unique needs and learning objectives of intellectually disabled children. Lets delve deeper into this topic by exploring the key aspects involved:
                        </p>

                        <div className="workshop d-inline-block d-lg-flex mb-2">
                   
                    <div className="video-container rounded-4 overflow-hidden">
                        <video
                            className="video"
                            controls
                            poster="https://i.ibb.co/c1SK0H8/workshop-pedagogy-and-curriculum.jpg"
                        >
                            <source src={video} type="video/mp4"></source>
                        </video>
                  
                    </div>
                    </div>


                      
                        <p>Here are some factors:</p>
                        <p>
                          <strong>Understanding the Needs of Intellectually Disabled Children:</strong> Before selecting topics for intellectually disabled children, its imperative to grasp the nuances of their needs. Intellectual disabilities encompass a spectrum of challenges that affect cognitive functioning, communication skills, and social interactions. Recognizing this diversity is foundational to crafting educational content that resonates with these children. From developmental delays to processing difficulties, each childs experience is unique, necessitating a tailored approach to learning.
                        </p>
                        <p>
                          <strong>Identifying Learning Objectives:</strong> Setting clear and attainable learning objectives is paramount in the validation process. These objectives serve as guiding principles, directing the content development towards fostering cognitive, social, and practical skills. Understanding the capabilities and limitations of intellectually disabled children is essential in crafting objectives that are both challenging and achievable, ultimately promoting growth and development.
                        </p>
                        <p>
                          <strong>Selecting Relevant Topics:</strong> The selection of topics is a delicate balance between capturing the childrens interests and addressing their educational needs. Topics should be meaningful and engaging, resonating with the childrens experiences and aspirations. From daily routines to community engagement, the chosen topics should offer opportunities for learning and exploration while catering to the unique interests of each child.
                        </p>
                        <p>
                          <strong>Ensuring Suitability:</strong> Suitability encompasses various facets, including the complexity of content, language accessibility, and sensory considerations. Materials should be presented in a manner that is easily digestible and engaging for intellectually disabled children. This may involve simplifying concepts, incorporating visual aids, or utilizing multisensory approaches to enhance comprehension and retention.
                        </p>
                        <p>
                          <strong>Alignment with Learning Objectives:</strong> Every topic should align closely with the identified learning objectives, ensuring coherence and purposefulness in the educational content. Whether the goal is to improve communication skills, foster social interaction, or develop practical life skills, each topic should contribute meaningfully to the overall learning trajectory of the children.
                        </p>
                        {/* <p>
                          <strong>Prioritization:</strong> After breaking the project down
                          into smaller features, it’s good to rank them in order of
                          priority. The simplest way to do this is to plot each feature in
                          the project onto a prioritization matrix based on urgency and
                          impact.
                        </p>
                        <p>
                          <strong>Solutions:</strong> Detail the current and proposed
                          solutions to the client’s problem. In this section, you’ll want to
                          include what the existing solution is (if any) and how users
                          interact with that solution. The second part of this section is to
                          outline your proposed solution in as much detail as possible. It
                          must be easy to understand—another engineer should be able to read
                          and build your proposed solution without any prior knowledge of
                          the project.
                        </p>
                        <h4>How to Create a Software Design Document</h4>
                        <p>
                          Keeping the above criteria in mind when creating your software
                          design document is a great start. To really maximize efficiency
                          and communication, there are a few best practices to implement.
                        </p>
                        <p>
                          Firstly, keep your language as simple as possible. The key is
                          clarity — especially when it comes to detailing technical points.
                          Include visuals into your document, helping readers accurately
                          understand the points and data you’re trying to convey. Diagrams,
                          charts, and other timelines are a great way to communicate
                          information.
                        </p>
                        <p>
                          Send a draft of your document to the client, so they can catch
                          parts you may have missed or areas that are unclear and need
                          fleshing out. Lastly, it’s important to update your document as
                          the project progresses, as you and other team members should be
                          consistently referencing the document.
                        </p>
                        <p>
                          When working on a new piece of software, it’s essential to craft a
                          software design document to create a clear and precise vision of
                          the client’s problem and your proposed solution. Software design
                          documents are important for detailing expectations between the
                          software engineer and the client. They help to streamline the
                          coding process before any code is written.
                        </p>
                        <p className="mb-0">
                          Read on to learn how to write great software design documents that
                          improve communication between you and your client, ensuring that
                          everyone is on the same page when working on a project together.
                        </p> */}
                      </div>
                    </div>
                    {/* /Blog Post */}
                  </div>
                  
                </div>
              </div>
            </section>

            {/* <Footer/> */}

        </div>
    </>
  )
}

export default workshopBlogDetails2;