import React from "react";
import "./style-disclaimer.css";

const Disclaimer = () => {

    return (
        <>
            <div className="main-wrapper ">
                <div className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">Disclaimer</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container py-4 disclaimer">
                    <strong>Disclaimer</strong>
                    <p className="mt-3">
                    We do not claim to provide medical or diagnostic services through &ldquo;E-Deekshaam&rdquo;. Any information, advice, or recommendations provided within the scope of this project should not be construed as a substitute for professional medical or psychological advice. It is imperative for individuals seeking such advice to consult with qualified healthcare professionals or specialists.
                    </p>
                    <p>
                        The goal of Our Project is to empower educators, caregivers, and other stakeholders with resources, strategies, and insights to support children in their development, learning, and well-being The Project is an initiative developed solely on expert review basis. It is essential to understand that the purpose of this project is not to diagnose or label any child with any particular issue or condition. Our intention is to provide support and assistance to children, teachers, and caregivers involved, aimed at improving their lives and fostering better learning and growth environments.
                    </p>
                    <p>
                        This Project is founded on the expertise of professionals in relevant fields, including but not limited to child psychology, education, and care giving. While our team has diligently researched and consulted with experts to ensure the effectiveness and relevance of the project, it is crucial to acknowledge that every child is unique, and their needs may vary.
                    </p>
                    <p>
                        We strive to promote inclusivity, understanding, and collaboration among all involved parties for the betterment of children&apos;s lives.
                    </p>
                    <p>
                        Participants engaging with this Project are encouraged to exercise discretion and judgment in implementing any recommendations or strategies provided. Every effort has been made to ensure the accuracy and reliability of the information presented; however, we cannot guarantee its applicability to every individual circumstance.
                    </p>
                    <p>
                        &quot;At this Project , we firmly believe in the uniqueness and individuality of every child. We do not seek to label children as having disabilities or limitations; rather, we recognize and celebrate their diverse strengths, abilities, and talents. Our mission is to empower teachers and caregivers with the tools, knowledge, and insights necessary to understand and support each child&apos;s distinct needs and preferences. By fostering a deeper understanding of every child&apos;s unique qualities, we aim to enrich their learning experiences, promote inclusivity, and ultimately enhance their overall well-being. Through e-Deekshaam, we strive to create environments where every child feels valued, understood, and supported in reaching their full potential.&quot;
                    </p>
                    <p>
                        By utilizing our Project , participants agree to indemnify and hold harmless the developers, contributors, and affiliates from any liabilities, damages, or losses arising from the use or misuse of the information or resources provided within the project.
                    </p>
                    <p>
                        We welcome feedback, suggestions, and inquiries regarding the Project. Your input is valuable in our ongoing efforts to enhance and refine our services to better serve the needs of children, educators, and caregivers.
                    </p>
                    <p>
                        Thank you for your understanding and cooperation.
                    </p>
                    <p>
                        Sincerely,
                    </p>
                    <p className="fw-bolder">
                        Maulana Azad National Urdu University
                    </p>
                </div>
            </div>
        </>
    );
};

export default Disclaimer;
