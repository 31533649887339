import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import video from "./videos/videoplayback1.mp4";
import "./style.css";
// import Blog8 from '../../assets/img/banner-img-1.png'
import Icon22 from "../header/headerImage/icon-22.svg";
import Icon23 from "../header/headerImage/icon-23.svg";
// import cardimage1 from '../header/headerImage/cardimage1.png'
// import cardimage2 from '../header/headerImage/cardimage2.png'
// import cardimage3 from '../header/headerImage/cardimage3.png';
// import cardimage4 from '../header/headerImage/cardimage4.jpg'
// import cardimage5 from '../header/headerImage/cardimage5.jpg'
// import cardimage12 from '../header/headerImage/service-05.png'
// import cardimage6 from '../header/headerImage/cardimage6.jpg'
// import BlogDetails from '../header/blogDetails';

const Workshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const getAlleventsdata = async () => {
    try {
      const response = await axios.get(
        `https://api.edeekshaam.in/v1/admin/events/getevents/workshop`
      );
      setEvents(response.data.response);
      console.log(response, "dfdadsadasd");
    } catch (error) {
      console.error("Error fetching data:", error);
      setEvents("");
    }
  };

  useEffect(() => {
    getAlleventsdata();
  }, []);

  const naviageHandler = (event) => {
    navigate(`/event/${event.id}`, { state: { videoUrl: event.videoUrl } });
  };

  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="main-wrapper">
      <div className="page-banner mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="mb-0">Workshop</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="course-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="row">
                  {Array.isArray(events) && events.length > 0 ? (
                    events.map((event) => (
                      <div className="col-md-4 col-sm-12" key={event.id}>
                        <div className="blog grid-blog">
                          <div className="blog-image">
                            <img
                              className="img-fluid"
                              src={`https://api.edeekshaam.in/v1/${event.thumbnailUrl}`}
                              alt="PostImage"
                              style={{ height: "250px" }}
                              crossOrigin="anonymous"
                            />
                          </div>
                          <div className="blog-grid-box">
                            <div className="blog-info clearfix">
                              <div className="post-left">
                                <ul>
                                  <li>
                                    <img
                                      className="img-fluid"
                                      src={Icon22}
                                      alt="PostImage"
                                    />
                                    {new Date(event.date).toLocaleDateString()}
                                  </li>
                                  <li>
                                    <img
                                      className="img-fluid"
                                      src={Icon23}
                                      alt="PostImage"
                                    />
                                    {capitalize(event.type)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <h3
                              className="blog-title"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {event.name}
                            </h3>

                            <div className="blog-content blog-read">
                              <p
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {event.content}
                              </p>
                              <div
                                className="read-more btn btn-primary"
                                onClick={() => naviageHandler(event)}
                              >
                                Read More
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="d-flex justify-content-center align-items-center text-xl">
                      Loading...
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Workshop;
