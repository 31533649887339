import React, { useEffect } from 'react'
// import { Link    } from 'react-router-dom';
// import workshopsData from './workshopsData';
// import { Blog1, Blog2, Blog3, BlogBanner, Icon22, Icon23, User } from '../imagepath';
// import BlogHeader from './header';
// import video from "../workshop/videos/videoplayback1.mp4";
// import Blog8 from '../../assets/img/banner-img-1.png'
import Icon22 from '../header/headerImage/icon-22.svg';
import Icon23 from '../header/headerImage/icon-23.svg';




const BlogDetails = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  })

  // const location = useLocation();
  // const receivedData = location.state?.data || "No data received";
  // console.log(receivedData,"receivedData22343");


  // const { data } = props.location.state;

  // const { id } = useParams();
  // const [workshopData, setWorkshopData] = useState(null);

  // useEffect(() => {
  //   // Find the workshop data by ID
  //   const workshop = workshopData.find(workshop => workshop.id === parseInt(id));
  //   if (workshop) {
  //     setWorkshopData(workshop);
  //   } else {
  //     console.error("Workshop not found");
  //   }
  // }, [id]);

  // if (!workshopData) {
  //   return <div>Loading...</div>;
  // }



  return (
    <>
        <div className='main-wrapper'>

            <section className="course-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-12">
                    {/* Blog Post */}
                    <div className="blog">
                   
                    
                     

             {/* <p>Data Received: {receivedData}</p> */}


                      <h3 className="blog-title">
                      {/* {workshopData.title} */}
                        One-Day Workshop on Pedagogy and Curriculum Needs of Children with Moderate Intellectual Disability
                    
                      </h3>
                      <div className="blog-info clearfix">
                        <div className="post-left">
                          <ul>
                            {/* <li>
                              <div className="post-author">
                                <Link  to="/instructor-profile">
                                  <img src={User} alt="Post Author" /> 
                                  <span>Ruby Perrin</span>
                                </Link> 
                              </div>
                            </li> */}
                            <li>
                              <img
                                className="img-fluid"
                                src={Icon22}
                                alt=""
                              />
                         22 April 2022
                            </li>
                            <li>
                              <img
                                className="img-fluid"
                                src={Icon23}
                                alt=""
                              />
                        Events, Workshops
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="blog-content">
                        <p>
                        A one-day workshop was organized specifically targeting the pedagogical and curriculum needs of children with moderate intellectual disabilities. The event was a response to the growing recognition of the unique challenges faced by this group of learners in mainstream educational settings. By dedicating a day to delve into these issues, the workshop aimed to shed light on effective strategies for addressing the diverse learning needs of children with moderate intellectual disabilities.
                        </p>
                        <p>
                        The participants of the workshop encompassed a wide spectrum of stakeholders involved in the education and care of children with moderate intellectual disabilities. Educators, special education specialists, psychologists, caregivers, and possibly parents or guardians all contributed their expertise and experiences to the discussions. This diverse representation ensured a comprehensive exploration of the multifaceted challenges associated with educating children with moderate intellectual disabilities.
                        </p>
                        {/* <h4>What Is a Software Design Document?</h4> */}
                     
                        <div className="video-container rounded-4 overflow-hidden">
                        <div
                          style={{
                            position: 'relative',
                            width: '1px',
                            minWidth: '100%',
                            paddingBottom: '56.25%',
                            marginBottom: '25px',
                          }}
                        >
                          <iframe
                            title="video-frame"
                            allow="autoplay"
                            className="spotlightr"
                            style={{ width: '1px', minWidth: '100%', height: '100%', position: 'absolute' }}
                            allowFullScreen="true"
                            src="https://iframe.mediadelivery.net/embed/238731/dadba2fa-30a7-4705-b50a-e7740c0d7049?autoplay=false&loop=false&muted=false&preload=true&responsive=true"
                            name="videoPlayer"
                          />
                        </div>
                      </div>
                   <p>
                        Throughout the workshop, the participants engaged in rich dialogues and exchanges centered around key topics relevant to pedagogy and curriculum development. One focal point of discussion was the adaptation of teaching methodologies to accommodate the varying learning styles and abilities within this demographic. Strategies for creating inclusive learning environments that promote active participation and meaningful engagement were also explored in depth.
                        </p>
                        <p>
                        In addition to addressing pedagogical concerns, the workshop delved into the intricacies of curriculum design for children with moderate intellectual disabilities. Participants examined approaches for developing curriculum materials that are accessible, relevant, and engaging for learners with diverse cognitive profiles. Emphasis was placed on the importance of individualized learning plans tailored to the unique strengths, interests, and challenges of each child.
                        </p>
                        {/* <h4>What to Include in Your Software Design Document</h4> */}
                        <p>
                        The workshop served as a platform for sharing best practices and evidence-based interventions proven to enhance the educational experiences of children with moderate intellectual disabilities. Participants had the opportunity to showcase successful initiatives implemented in their respective educational settings and exchange insights on what works effectively in practice. By leveraging collective expertise and experiences, the workshop aimed to facilitate the dissemination of innovative approaches that can be replicated and scaled across diverse educational contexts.


                        </p>
                        <p>Here are some factors:</p>
                        <p>
                          <strong>Event:</strong> The workshop was a one-day event, indicating it was a focused session rather than a longer conference or seminar.
                        </p>
                        <p>
                          <strong>Purpose:</strong> The primary objective of the workshop was to discuss the specific educational needs of children with moderate intellectual disabilities. This suggests a focus on understanding how to best teach and support these children in their learning.
                        </p>
                        <p>
                          <strong>Participants:</strong> The workshop likely brought together educators, specialists, caregivers, and possibly parents or guardians of children with moderate intellectual disabilities. These participants would share insights, experiences, and knowledge related to teaching and supporting children with such disabilities.
                        </p>
                        <p>
                          <strong>Topics Covered:</strong> The workshop likely covered various aspects related to pedagogy and curriculum design tailored to the needs of children with moderate intellectual disabilities. This could include strategies for adapting teaching methods, creating inclusive learning environments, and designing curriculum materials that meet the diverse needs of these children.
                        </p>
                        <p>
                          <strong>Outcomes:</strong> The expected outcome of the workshop would be to generate ideas, strategies, and recommendations for improving the educational experiences and outcomes of children with moderate intellectual disabilities. Participants may have developed action plans or identified areas for further research and collaboration.

Overall, the workshop served as a platform for stakeholders to come together, share knowledge and expertise, and work towards enhancing the educational opportunities for children with moderate intellectual disabilities.
                        </p>
                        {/* <p>
                          <strong>Prioritization:</strong> After breaking the project down
                          into smaller features, it’s good to rank them in order of
                          priority. The simplest way to do this is to plot each feature in
                          the project onto a prioritization matrix based on urgency and
                          impact.
                        </p>
                        <p>
                          <strong>Solutions:</strong> Detail the current and proposed
                          solutions to the client’s problem. In this section, you’ll want to
                          include what the existing solution is (if any) and how users
                          interact with that solution. The second part of this section is to
                          outline your proposed solution in as much detail as possible. It
                          must be easy to understand—another engineer should be able to read
                          and build your proposed solution without any prior knowledge of
                          the project.
                        </p>
                        <h4>How to Create a Software Design Document</h4>
                        <p>
                          Keeping the above criteria in mind when creating your software
                          design document is a great start. To really maximize efficiency
                          and communication, there are a few best practices to implement.
                        </p>
                        <p>
                          Firstly, keep your language as simple as possible. The key is
                          clarity — especially when it comes to detailing technical points.
                          Include visuals into your document, helping readers accurately
                          understand the points and data you’re trying to convey. Diagrams,
                          charts, and other timelines are a great way to communicate
                          information.
                        </p>
                        <p>
                          Send a draft of your document to the client, so they can catch
                          parts you may have missed or areas that are unclear and need
                          fleshing out. Lastly, it’s important to update your document as
                          the project progresses, as you and other team members should be
                          consistently referencing the document.
                        </p>
                        <p>
                          When working on a new piece of software, it’s essential to craft a
                          software design document to create a clear and precise vision of
                          the client’s problem and your proposed solution. Software design
                          documents are important for detailing expectations between the
                          software engineer and the client. They help to streamline the
                          coding process before any code is written.
                        </p>
                        <p className="mb-0">
                          Read on to learn how to write great software design documents that
                          improve communication between you and your client, ensuring that
                          everyone is on the same page when working on a project together.
                        </p> */}
                      </div>
                    </div>
                    {/* /Blog Post */}
                  </div>
                  
                </div>
              </div>
            </section>

            {/* <Footer/> */}

        </div>
    </>
  )
}

export default BlogDetails;