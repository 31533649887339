import React from "react";
// import "../../style-disclaimer.css";

const Terms = () => {

    return (
        <>
            <div className="main-wrapper ">
                <div className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">Terms and Conditions</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4 disclaimer">
                    <strong>Terms and Conditions</strong> <br/>
                    <p className="mt-4" style={{fontWeight:"500"}}>1. Acceptance of Terms</p>
                    <p className="mt-3">
                    By accessing or using the &#32;E-Deekshaam&#32; website (hereinafter referred to as the &#32;Website&#32;), agree to comply with and be bound by these terms and conditions.
                    </p>

                    <p className="mt-4" style={{fontWeight:"500"}}>2. Use of Information and Content</p>
                    <p>
                    The information, advice, and recommendations provided on the Website are for general informational purposes only, not intended to be a substitute for professional medical, psychological, or therapeutic advice, diagnosis, or treatment. Always seek the advice of qualified healthcare professionals or specialists for any medical or psychological concerns.
                    </p>
                    
                    <p className="mt-4" style={{fontWeight:"500"}}>3. Intellectual Property</p>
                  <p>
                    All content, including text, graphics, logos, images, and software, on the Website is the property of Maulana Azad National Urdu University or its content suppliers and is protected by international copyright laws. Unauthorized use of any content may violate copyright, trademark, and other laws.
                    </p>

                    <p className="mt-4" style={{fontWeight:"500"}}>4. User Responsibilities</p>
                        <p>
                        Users are solely responsible for their use of the Website and for any content they post or share. Agree not to:
                        <br/><br/>
                        a. Use the Website in any way that violates any applicable laws or regulations.<br/>
                        b. Post or transmit any unlawful, defamatory, obscene, or offensive material.<br/>
                        c. Attempt to gain unauthorized access to any part of the Website, servers, or networks.
                        </p>
                        <p className="mt-4" style={{fontWeight:"500"}}>5. Limitation of Liability</p>
                        <p>
                        Maulana Azad National Urdu University, its developers, contributors, and affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use or inability to use the Website or the information provided, even if advised of the possibility of such damages.
                        </p>
                       <p className="mt-4" style={{fontWeight:"500"}}>6. Indemnification</p>
                        <p>
                        Maulana Azad National Urdu University, its developers, contributors, , affiliates ,indemnify and hold harmless from any liabilities, damages, or losses arising from use or misuse of the Website, violation of these terms and conditions, or infringement of any intellectual property or other right of any person or entity.
                        </p>



                    <p className="mt-4" style={{fontWeight:"500"}}>7. Changes to Terms and Conditions
                    </p>
                    <p>
                    Maulana Azad National Urdu University reserves the right to modify or update these terms and conditions at any time without prior notice. The continued use of the Website after any changes constitutes acceptance of the updated terms and conditions.
                    </p>
                    <p className="mt-4" style={{fontWeight:"500"}}>8. Feedback and Suggestions
                    </p>
                <p>
                    We welcome feedback, suggestions, and inquiries regarding the Website. The feekback is valuable in our ongoing efforts to enhance and refine our services to better serve the needs of individuals with intellectual disabilities, educators, and caregivers.
                    </p>
                    <p className="mt-4" style={{fontWeight:"500"}}>9. Governing Law</p>
                 <p>
                    These terms and conditions shall be governed by and in accordance with the laws of e-Deekshaam, without regard to its conflict of law principles.
                    </p>
                    <p className="mt-4" style={{fontWeight:"500"}}>10. Contact Information</p>
                    <p>
                    For any questions or concerns regarding these terms and conditions, please contact us at <a href="mailto:contact@edeekshaam.in" style={{fontWeight:"500"}}>contact@edeekshaam.in
                    </a>
                    </p>




                    
                    <p>
                        Thank you for your understanding and cooperation.
                    </p>
                    <p>
                        Sincerely,
                    </p>
                    <p className="fw-bolder">
                        Maulana Azad National Urdu University
                    </p>
                </div>
            </div>
        </>
    );
};

export default Terms;
