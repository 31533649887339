import React, { useState, useEffect } from "react";
import { Mail, Phone } from "react-feather";
import { Link } from "react-router-dom";
import logoPng from "./image/edeekshaam-footer-logo.png";

import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  const [isAboutSubMenuOpen, setAboutSubMenuOpen] = useState(false);
  const [isEventSubMenuOpen, setEventSubMenuOpen] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const toggleEventSubMenu = () => {
    setEventSubMenuOpen(!isEventSubMenuOpen);
    setAboutSubMenuOpen(false);
  };

  const toggleAboutSubMenu = () => {
    setAboutSubMenuOpen(!isAboutSubMenuOpen);
    setEventSubMenuOpen(false);
  };

  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const styles = {
    submenu: {
      display: "none",
      listStyleType: "none",
      paddingLeft: "5px",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
    aboutSubmenuVisible: {
      display: "block",
      opacity: 1,
    },
    eventSubmenuVisible: {
      display: "block",
      opacity: 1,
    },
    submenuLinks: {
      color: "white",
      textDecoration: "none",
      listStyleType: "none",
    },
  };

  return (
    <footer className="footer footer-three" data-aos="fade-up">
      {/* Footer Top */}
      <div className="footer-three-top" data-aos="fade-up">
        <div className="container">
          <div className="footer-three-top-content">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-5 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-about">
                  <div className="footer-three-logo">
                    <Link to="/about-edeeksham" onClick={scrollToTop}>
                      <img
                        className="img-fluid"
                        src={logoPng}
                        alt="logo"
                        style={{ width: "33%" }}
                      />
                    </Link>
                  </div>
                  <div className="footer-three-about">
                    <p>
                      Adaptive Assistive System for Children with Moderate
                      Intellectual Disability
                    </p>
                  </div>
                </div>
                {/* Footer Widget */}
              </div>

              <div className="col-lg-3 col-md-3 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-menu-three footer-three-right">
                  <h6 className="footer-three-title">Quick Links</h6>
                  <ul>
                    <li>
                      <Link to="/" onClick={scrollToTop}>
                        Home
                      </Link>
                    </li>
                    <li className="has-submenu">
                      <Link onClick={toggleAboutSubMenu} className="text-white">
                        About{" "}
                        <i
                          className="fas fa-chevron-down"
                          onClick={openMobileSubMenu3}
                        ></i>
                      </Link>
                      <ul
                        style={
                          isAboutSubMenuOpen
                            ? {
                                ...styles.submenu,
                                ...styles.aboutSubmenuVisible,
                              }
                            : styles.submenu
                        }
                        className="ms-3"
                      >
                        <li style={{ listStyleType: "none" }}>
                          <Link
                            to="/about-edeeksham"
                            style={styles.submenuLinks}
                            onClick={scrollToTop}
                          >
                            e-Deekshaam
                          </Link>
                        </li>
                        <li style={{ listStyleType: "none" }}>
                          <Link
                            to="/about-manuu"
                            style={styles.submenuLinks}
                            onClick={scrollToTop}
                          >
                            MANUU
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link onClick={toggleEventSubMenu} className="text-white">
                        Events{" "}
                        <i
                          className="fas fa-chevron-down"
                          onClick={openMobileSubMenu3}
                        ></i>
                      </Link>
                      <ul
                        style={
                          isEventSubMenuOpen
                            ? {
                                ...styles.submenu,
                                ...styles.eventSubmenuVisible,
                              }
                            : styles.submenu
                        }
                        className="ms-3"
                      >
                        <li style={{ listStyleType: "none" }}>
                          <Link
                            to="/workshop"
                            style={styles.submenuLinks}
                            onClick={scrollToTop}
                          >
                            Workshops
                          </Link>
                        </li>
                        <li style={{ listStyleType: "none" }}>
                          <Link
                            to="/other-events"
                            style={styles.submenuLinks}
                            onClick={scrollToTop}
                          >
                            Other events
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/collaboration" onClick={scrollToTop}>
                        Collaboration
                      </Link>
                    </li>
                    <li>
                      <Link to="/important-links" onClick={scrollToTop}>
                        Important links
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={scrollToTop}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* Footer Widget */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                {/* Footer Widget */}
                <div className="footer-widget-three footer-menu-three">
                  <h6 className="footer-three-title">Contact us</h6>
                  <p className="text-light">
                    Department of Computer Science & Information Technology,
                    <br /> Maulana Azad National Urdu University,
                    <br />
                    Gachibowli, Hyderabad, Telangana, India - 500032
                  </p>
                  <div className="row m-0 p-0">
                    <Mail
                      style={{
                        color: "white",
                        width: "auto",
                        margin: "0px",
                        padding: "0px",
                      }}
                    />
                    <Link
                      to="mailto:contact@edeekshaam.in"
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                        width: "auto",
                      }}
                    >
                      contact@edeekshaam.in
                    </Link>
                  </div>
                  <div className="row m-0 p-0 mt-3">
                    <Phone
                      style={{
                        color: "white",
                        width: "auto",
                        margin: "0px",
                        padding: "0px",
                      }}
                    />
                    <a
                      href="tel:04023006612"
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                        width: "auto",
                      }}
                    >
                      040 23006612 (Extn : 4002)
                    </a>
                    <p
                      className="mt-3 p-0"
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                        width: "auto",
                      }}
                    >
                      Call Timing: 9:30 AM to 5:30 PM (Monday to Friday)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Top */}

      {/* Footer Bottom */}
      <div className="footer-three-bottom">
        <div className="container">
          {/* Copyright */}
          <div className="copyright-three">
            <div className="row">
              <div className="col-md-12">
                <div className="privacy-policy-three">
                  <ul>
                    <li>
                      <Link to="/disclaimer" onClick={scrollToTop}>
                        Disclaimer
                      </Link>
                    </li>

                    <li>
                      <Link to="/Terms" onClick={scrollToTop}>
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text-three">
                  <p>&copy; 2024 ε-Deekshaam. All rights reserved.</p>
                  <p className="mb-0">
                    Please note that since the system is currently in its{" "}
                    <strong className="text-yellow">Beta version</strong>, there
                    may be some bugs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Copyright */}
        </div>
      </div>
      {/* Footer Bottom */}
    </footer>
  );
};

export default Footer;
